// @ts-ignore
const resetStore = () => next => (reducer, initialState, enhancer) => {
  // @ts-ignore
  const enhanceReducer = (state, action) => {
    let newState = state;
    if (action.type === 'AUTH_LOGOUT_SUCCESS') {
      sessionStorage.clear();
      localStorage.clear();
      newState = undefined;
    }

    return reducer(newState, action);
  };
  return next(enhanceReducer, initialState, enhancer);
};

export default resetStore;
