import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { actions } from 'redux/ducks/tophint.duck';
import { effects as actionsProject, selectors } from 'redux/ducks/project.duck';
import i18n from 'i18next';
import Button from 'components/UI/Buttons';
import Loader from 'components/UI/Loader';
import CustomSelect from 'components/UI/CustomSelect';

import logo from 'assets/terratraf.svg';

import './styles.scss';

type PropsOpenTophint = {
  text: String;
  type: String;
};

type Props = {
  openTophint: (val: PropsOpenTophint) => void;
  history: any;
  getListProjects: () => void;
  list: any;
  loading: boolean;
};

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid #ddd',
  }),
};

const StartPage = ({
  openTophint,
  getListProjects,
  list,
  loading,
  history,
}: Props) => {
  const [img, setImg] = useState<null | string>(null);

  useEffect(() => {
    getListProjects();

    return () => {};
  }, []);

  return (
    <div
      className={`start-page start-page_connected ${
        loading ? 'start-page_loading' : ''
      }`}
    >
      <div className="start-page__bg" />
      <div className="start-page__content">
        <div className="container">
          <div className="start-page__header">
            <div className="start-page__logo">
              {img ? <img src={img} /> : <img src={logo} alt="Terratraf" />}
            </div>
            <div className="start-page__loader">
              <Loader />
            </div>
          </div>
          <Formik
            onSubmit={({ projectId }, actions) => {
              if (list.some((e: any) => e.id === projectId.value)) {
                if ((window as any).androidJsBridge) {
                  (window as any).androidJsBridge.selectProject(
                    projectId.value,
                  );
                }

                if ((window as any).iosJsBridge) {
                  (window as any).iosJsBridge.call('selectProject', [
                    projectId.value,
                  ]);
                }

                return setTimeout(() => {
                  history.push({
                    pathname: '/auth/login',
                    state: { projectId: projectId.value },
                  });
                }, 200);
              }

              return actions.setFieldError('projectId', 'Invalid project');
            }}
            initialValues={{
              projectId: { value: '', label: '' },
            }}
            render={({ values, setFieldValue, errors, touched }) => (
              <Form className="start-page__form">
                <CustomSelect
                  label={i18n.t('name_project')}
                  // @ts-ignore
                  error={errors.projectId && touched.projectId}
                  errortext={errors.projectId}
                >
                  <Select
                    styles={customStyles}
                    value={values.projectId}
                    placeholder={i18n.t('name_project')}
                    name="projectId"
                    className="customSelect"
                    options={
                      list.length > 0
                        ? list.map(({ id, name }: any) => ({
                            value: id,
                            label: name,
                          }))
                        : []
                    }
                    onChange={(e: any) => {
                      setFieldValue('projectId', e);
                      setImg(list.find((el: any) => el.id === e.value).logo);
                    }}
                  />
                </CustomSelect>
                <Button type="submit" title={i18n.t('next')} />
              </Form>
            )}
          />
          <div className="start-page__version">
            <span>Interface ver. 0.1.344</span>
            <span>App ver. 1.0.9</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    list: selectors.getList(state),
    loading: selectors.getLoading(state),
  }),
  { ...actions, ...actionsProject },
)(StartPage as any);
