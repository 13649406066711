import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const actions = createActions('INIT');

const effects = {};

const reducer = handleActions(
  {
    // [actions.fetchDataTableRequest]: state => ({
    //   ...state,
    //   loading: true,
    // }),
  },
  initialState,
);

const getState = (state: any) => state.main;

const cs = (cb: any) =>
  createSelector(
    [getState],
    cb,
  );

const selectors = {
  // getMainData: cs(s => s.data),
  // getLoading: cs(s => s.loading),
  // getErrors: cs(s => s.error),
};

export { initialState as state, actions, effects, reducer, selectors };
