import * as React from 'react';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { effects } from 'redux/ducks/auth.duck';
import { selectors } from 'redux/ducks/project.duck';
import i18n from 'i18next';

import Button from 'components/UI/Buttons';
import Input from 'components/UI/Input';

import { FiChevronLeft, FiUser, FiLock } from 'react-icons/fi';
import logo from 'assets/login-images/login-logotype.svg';
import './styles.scss';

type SinInProps = {
  projectId: String;
  email: String;
  password: String;
};

// type State = {
//   loading: Boolean;
//   error: null | String;
//   signIn: (val: SinInProps) => void;
//   location: any;
// }

type Props = {
  loading: Boolean;
  error: null | String;
  signIn: (val: SinInProps) => void;
  location: any;
  history: any;
  fetchUser: any;
  list: any;
};

const Login: React.FC<Props> = ({ location, history, signIn, list }) => (
  <div className="login-page">
    <div className="login-page__header">
      <div className="container">
        <div onClick={() => history.push('/auth/start')}>
          <FiChevronLeft size={24} />
        </div>
      </div>
    </div>
    <div className="login-form">
      <div className="container">
        <div className="login-form__header">
          <img
            src={
              list.find((e: any) => e.id === location.state.projectId).logo ||
              logo
            }
            alt="logo"
          />
        </div>

        <Formik
          onSubmit={values => {
            const { state } = location;
            // @ts-ignore
            signIn({ ...values, projectId: state.projectId }, history);
          }}
          validate={values => {
            let errors: any = {};

            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }

            if (values.password.length < 2) {
              errors.password = 'Invalid password address';
            }

            return errors;
          }}
          initialValues={{
            email: '',
            password: '',
          }}
          render={({ errors, touched }) => (
            <Form className="login-form__form">
              <Input
                type="string"
                name="email"
                label={i18n.t('email')}
                placeholder="admin@gmail.com"
                icon={<FiUser size={24} />}
                // @ts-ignore
                error={errors.email && touched.email}
                errortext={errors.email}
              />

              <Input
                type="password"
                name="password"
                label={i18n.t('password')}
                icon={<FiLock size={24} />}
                // @ts-ignore
                error={errors.password && touched.password}
                errortext={errors.password}
              />

              <div className="login-form__forgot">
                <Link to="/">{i18n.t('forgot_password')}</Link>
              </div>

              <Button type="submit" title={i18n.t('login')} />
            </Form>
          )}
        />

        <div className="login-form__footer">
          <span>{i18n.t('new_user')}</span>{' '}
          <Link to="/auth/signup">{i18n.t('sign_up')}</Link>
        </div>
      </div>
    </div>
    <div className="login-page__footer">
      <div className="copyright">
        <span>Adxxx.com © 2019</span>
      </div>
      <div className="lang-select">
        <div className="lang-select__selected">
          <span>Eng</span>
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 7L7 1L13 7"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="lang-select__list">
          <div className="lang-select__item">
            <span>English</span>
          </div>
          <div className="lang-select__item">
            <span>Русский</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default connect(
  state => ({
    list: selectors.getList(state),
  }),
  { ...effects },
)(Login as any);
