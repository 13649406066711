import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { effects, selectors } from 'redux/ducks/user.duck';
import i18n from 'i18next';
import Loader from 'components/UI/Loader';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Start from './Start';
import Login from './Login';
import Main from './Main';

import './styles.scss';

type Props = {
  fetchUser: () => void;
  user: any;
  loading: boolean;
};

const RootRoutes: React.FC<Props> = ({ fetchUser, user, loading }) => {
  useEffect(() => {
    fetchUser();
    onLanguage();

    return () => {};
  }, [fetchUser]);

  const onLanguage = async () => {
    const getNavigatorLanguage = async () => {
      let response;
      await new Promise(resolve => setTimeout(resolve, 200));

      if ((window as any).iosJsBridge && (window as any).iosJsBridge.call) {
        response = await (window as any).iosJsBridge.call('getLang');
      }

      if (
        (window as any).androidJsBridge &&
        ((window as any).androidJsBridge as any).getLang
      ) {
        (window as any).androidJsBridge.getLang();

        response = await new Promise((resolve, rej) => {
          (window as any).getLangResult = (data: any) => {
            resolve(data);
          };
        });
      }

      if (navigator.languages && navigator.languages.length) {
        response = navigator.languages[0];
      }

      return response || navigator.language || 'ru';
    };

    const data = await getNavigatorLanguage();
    const language = data.split('-')[0];

    i18n.changeLanguage(language);
  };

  if (loading || user === null) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  return (
    <Switch>
      <PrivateRoute
        exact
        path="/"
        component={Main}
        hasUser={user.isAuthorized}
      />
      <PublicRoute
        exact
        path="/auth/start"
        component={Start}
        hasUser={user.isAuthorized}
      />
      <PublicRoute
        exact
        path="/auth/login"
        component={Login}
        hasUser={user.isAuthorized}
      />
    </Switch>
  );
};

export default connect(
  state => ({
    user: selectors.getUser(state),
    loading: selectors.getLoading(state),
  }),
  { ...effects },
)(RootRoutes as any);
