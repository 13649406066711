import React from 'react';
import './styles.scss';

const Loader = () => (
  <div className="loader">
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="10.5"
        transform="matrix(0.798509 0.601982 0.601982 -0.798509 16.8549 16.8549)"
        stroke="#025DFF"
        strokeWidth="3"
      />
    </svg>
  </div>
);

export default Loader;
