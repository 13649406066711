import React from 'react';
import { Redirect, Route } from 'react-router-dom';

type Props = {
  component: any;
  exact?: any;
  path: string;
  hasUser: any;
};

const PublicRoute: React.FC<Props> = ({
  component: Component,
  hasUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return hasUser ? <Redirect to="/" /> : <Component {...props} />;
    }}
  />
);

export default PublicRoute;
