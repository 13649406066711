import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  date: String;
  message: String;
  type: String;
  icon: String;
  title: String;
};

const NotificationPage = ({ type, icon, title, date, message }: Props) => {
  return (
    <div className={cn('notification-page', type)}>
      <div className="notification-page__bg" />
      <div className="notification-page__content">
        <div className="notification-page__info">
          <div className="container">
            <div className="notification-page__icon">{icon}</div>
            <div className="notification-page__title">
              <span>{title}</span>
            </div>
            <div className="notification-page__dots" />
            <div className="notification-page__date">
              <span>{date}</span>
            </div>
            <div className="notification-page__message">
              <span>{message}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;

NotificationPage.defaultProps = {
  type: 'error',
  icon: '',
  title: 'Внимание!',
  date: 'Прямо сейчас',
  message: 'Ошибка получения данных',
};
