import React from 'react';
import cn from 'classnames';
import { Field } from 'formik';
import { MdInfoOutline } from 'react-icons/md';
import input from './styles.module.scss';

type Props = {
  label: String;
  icon?: any | undefined;
  error?: boolean | undefined;
  errortext?: String;
  type?: String;
  name: String;
  placeholder?: String;
};

const Input = ({ label, icon, error, errortext, ...rest }: Props) => {
  return (
    <div
      className={cn(
        input['form-group'],
        error ? input['form-group_error'] : '',
      )}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      {label && <label htmlFor="#">{label}</label>}
      <div>
        <Field {...rest} />
        {icon}
      </div>

      {!!errortext && (
        <span className={input['form-group__errorText']}>
          <MdInfoOutline size="16" />
          {errortext}
        </span>
      )}
    </div>
  );
};

export default Input;
