import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { resetStore } from './middleware';
import rootReducer from './rootReducer';

const store = createStore(
  rootReducer(),
  compose(
    applyMiddleware(createLogger(), thunk),
    resetStore(),
  ),
);

export type AppState = ReturnType<typeof rootReducer>;

// @ts-ignore
window.store = store;

export { store };

export default () => {
  if (process.env.NODE_ENV !== 'production') {
    // @ts-ignore
    if (module.hot) {
      // @ts-ignore
      module.hot.accept('./rootReducer', () => {
        store.replaceReducer(rootReducer());
      });
    }
  }
  return { store };
};
