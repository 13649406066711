import React from 'react';
import { Field, FieldProps } from 'formik';
import { FiCheck } from 'react-icons/fi';
import radio from './styles.module.scss';

type Props = {
  label: String;
  type: String;
  name: String;
  value: String;
  disabled: Boolean | undefined;
};

const Radio = ({ label, ...rest }: Props) => {
  return (
    <div className={radio['form-group']}>
      <label>
        <Field {...rest} />
        <span>{label}</span>
        <FiCheck size={24} color="#025DFF" />
      </label>
    </div>
  );
};

export default Radio;

Radio.defaultProps = {
  type: 'radio',
};
