import React, { useState, ReactElement } from 'react';
import { useSpring, animated } from 'react-spring';
import { useSwipeable } from 'react-swipeable';
import { debounce } from 'debounce';
import { FiChevronLeft, FiX } from 'react-icons/fi';
import './styles.scss';

type Props = {
  closeModal: () => void;
  openModal: () => void;
  children: ReactElement;
  isOpen: Boolean;
  zIndex: Number;
  title: String;
  isArrow: Boolean;
  isClose: Boolean;
  slide: String;
};

const ModalContainer = ({
  closeModal,
  openModal,
  isOpen,
  zIndex,
  children,
  title,
  isArrow,
  isClose,
  slide,
}: Props) => {
  const [left, setLeft] = useState<Number>(0);

  const propsBg = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: {
      duration: 250,
    },
  });

  const slideTransform = slide === 'left' ? 'translateX' : 'translateY';

  // @ts-ignore
  const [props, set, stop] = useSpring(() => ({
    // @ts-ignore
    to: async (next, cancel): any => {
      if (isOpen) {
        await next({
          transform: `${slideTransform}(0px)`,
        });
        await next({ transform: `${slideTransform}(2px)` });
        await next({ transform: `${slideTransform}(0)` });
        await cancel();
      }
    },
    from: {
      transform: `${slideTransform}(100%)`,
    },
    config: {
      duration: 100,
    },
  }));

  const handlers = useSwipeable({
    onSwiping: event => {
      // @ts-ignore
      stop();
      const { dir, absX, absY } = event;

      if (slide === 'bottom' && dir === 'Down') {
        // @ts-ignore
        return debounce(setLeft(Math.ceil(absY)), 50);
      }

      if (dir === 'Right') {
        // @ts-ignore
        return debounce(setLeft(Math.ceil(absX)), 50);
      }

      return null;
    },
    onSwiped: event => {
      const { absX, absY } = event;
      const { width, height } = document.body.getBoundingClientRect();
      const total = ((width - Math.ceil(absX)) / width) * 100;
      const totalHeight = ((height - Math.ceil(absY)) / height) * 100;

      if (totalHeight < 60 && slide === 'bottom') {
        return closeModal();
      }

      if (total < 55 && slide === 'left') {
        return closeModal();
      }

      return setLeft(0);
    },
    delta: 40,
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });

  return (
    <React.Fragment>
      <animated.div style={propsBg} className="modal_bg" />
      <div
        className="modal"
        {...handlers}
        // @ts-ignore
        style={{ transform: `${slideTransform}(${left}px)`, zIndex }}
      >
        <animated.div style={props} className="modal__dialog">
          <div className="modal__header">
            <div className="container">
              {isArrow && (
                <div className="arrow" onClick={closeModal} aria-hidden>
                  <FiChevronLeft size={24} />
                </div>
              )}

              {title && (
                <div className="text">
                  <span>{title}</span>
                </div>
              )}

              {isClose && (
                <div className="close" onClick={closeModal} aria-hidden>
                  <FiX size={24} />
                </div>
              )}
            </div>
          </div>

          {React.cloneElement(children, { closeModal, openModal })}
        </animated.div>
      </div>
    </React.Fragment>
  );
};

ModalContainer.defaultProps = {
  zIndex: 1000,
  isClose: true,
  isArrow: false,
  title: ' ',
  slide: 'left',
};

export default ModalContainer;
