import React, { useEffect } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { actions, selectors } from 'redux/ducks/tophint.duck';
import TopHint from 'components/UI/TopHint';
import MainPortal from './MainPortal';
import 'components/UI/TopHint/styles.scss';

type Props = {
  isOpen: Boolean;
  type: String;
  text: String;
  closeTophint: () => void;
};

const TopHintModal = (props: Props) => {
  const { isOpen, type, closeTophint, text } = props;
  useEffect(() => {
    // @ts-ignore
    let onDeactive: NodeJS.Timer | undefined;

    if (isOpen) {
      onDeactive = setTimeout(() => closeTophint(), 5000);
    }

    return () => {
      // @ts-ignore
      clearTimeout(onDeactive);
    };
  }, [isOpen, closeTophint]);

  return (
    <MainPortal>
      <div className={cn(`top-hint ${type}`, { show: isOpen })}>
        <TopHint text={text} closeTophint={closeTophint} />
      </div>
    </MainPortal>
  );
};

TopHintModal.defaultProps = {
  type: 'info',
};

export default connect(
  state => ({
    isOpen: selectors.isOpen(state),
    text: selectors.getText(state),
    type: selectors.getType(state),
  }),
  { ...actions },
  // @ts-ignore
)(TopHintModal);
