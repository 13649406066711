import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

type State = {
  type: null | String;
  open: Boolean;
  args?: null | any;
};

const initialState = {
  type: null,
  open: false,
  args: null,
};

const actions = createActions('CLOSE_MODAL', 'OPEN_MODAL');

const reducer = handleActions(
  {
    [actions.openModal.toString()]: (state, { payload: { type, args } }) => ({
      ...state,
      open: true,
      type,
      args,
    }),
    [actions.closeModal.toString()]: () => ({ ...initialState }),
  },
  initialState,
);

const getState = (state: any) => state.modals;

const cs = (cb: any) =>
  createSelector(
    [getState],
    cb,
  );

const selectors = {
  getType: cs((s: State) => s.type),
  isOpen: cs((s: State) => s.open),
  getArgs: cs((s: State) => s.args),
};

export { initialState as state, reducer, actions, selectors };
