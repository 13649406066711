import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { effects } from 'redux/ducks/auth.duck';
import i18n from 'i18next';
import {
  FiSettings,
  FiChevronRight,
  FiLogOut,
  FiGlobe,
  FiBell,
} from 'react-icons/fi';

import './styles.scss';

const MenuPage = ({ history, closeModal, logOut }: any) => (
  <div className="menu-page">
    <div className="menu-page__content">
      <div className="menu-page__list">
        <div className="menu-page__item menu-page__item_message">
          <div className="container">
            <div className="icon">
              <FiSettings size={24} />
            </div>
            <div className="text">{i18n.t('settings')}</div>
            <div className="link">
              <FiChevronRight size={24} />
            </div>
          </div>
        </div>
        <div className="menu-page__item menu-page__item_message">
          <div className="container">
            <div className="icon">
              <FiBell size={24} />
            </div>
            <div className="text">{i18n.t('notifications')}</div>
          </div>
        </div>
        <div className="menu-page__item">
          <div className="container">
            <div className="icon">
              <FiGlobe size={24} />
            </div>
            <div className="text">
            {i18n.t('language')} · <span>Русский</span>
            </div>
          </div>
        </div>

        <div
          className="menu-page__item"
          onClick={() => logOut(history, closeModal)}
          aria-hidden
        >
          <div className="container">
            <div className="icon">
              <FiLogOut size={24} />
            </div>
            <div className="text">{i18n.t('logout')}</div>
          </div>
        </div>
      </div>
      <div className="menu-page__footer">
        <span>Interface ver. 0.1.344</span>
        <span>App ver. 1.0.9</span>
      </div>
    </div>
  </div>
);

export default connect(null, {...effects})(withRouter(MenuPage));
