import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { AppState } from '../store';

type State = {
  type: null | String;
  open: Boolean;
  text: null | String;
};

const initialState = {
  type: null,
  open: false,
  text: null,
};

const actions = createActions('CLOSE_TOPHINT', 'OPEN_TOPHINT');

const reducer = handleActions(
  {
    [actions.openTophint.toString()]: (state, { payload: { type, text } }) => ({
      ...state,
      open: true,
      type,
      text,
    }),
    [actions.closeTophint.toString()]: () => ({ ...initialState }),
  },
  initialState,
);

const getState = (state: any) => state.tophint;

const cs = (cb: any) =>
  createSelector(
    [getState],
    cb,
  );

const selectors = {
  getType: cs((s: State) => s.type),
  isOpen: cs((s: State) => s.open),
  getText: cs((s: State) => s.text),
};

export { initialState as state, reducer, actions, selectors };
