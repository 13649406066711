import React from 'react';
import { Field } from 'formik';
import checkbox from './styles.module.scss';

interface Props {
  label: String;
  type: String;
  name: String;
}

const SettingsCheckbox = ({ label, ...rest }: Props) => {
  return (
    <div className={checkbox['form-group']}>
      <label>
        <Field {...rest} />
        <span>{label}</span>
      </label>
    </div>
  );
};

export default SettingsCheckbox;
