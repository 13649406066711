import { handleActions, createActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { actions as actionsTopHint } from './tophint.duck';

type Balance = {
  founds: String;
  spents: String;
  balance: String;
  is_unlimited: boolean;
  currency: String;
};

type State = {
  loading: Boolean;
  balanceLoading: Boolean;
  error: null | string;
  balance: Balance;
  user: null | any;
};

const initialState = {
  user: null,
  balance: null,
  balanceLoading: false,
  loading: false,
  error: null,
};

const actions = createActions(
  'FETCH_BALANCE_SUCCESS',
  'FETCH_BALANCE_REQUEST',
  'FETCH_BALANCE_FAILURE',

  'FETCH_USER_SUCCESS',
  'FETCH_USER_FAILURE',
  'FETCH_USER_REQUEST',

  'RESET_USER',
);

const effects = {
  getBalance: () => async (dispatch: Dispatch) => {
    try {
      let response = JSON.stringify({});
      dispatch(actions.fetchBalanceRequest());

      if ((window as any).androidJsBridge) {
        (window as any).androidJsBridge.getUserBalance();

        response = await new Promise((resolve, rej) => {
          (window as any).getUserBalanceResult = (data: any) => {
            resolve(data);
          };
        });
      }

      if ((window as any).iosJsBridge) {
        response = await (window as any).iosJsBridge.call('getUserBalance');
      }

      const { data } = JSON.parse(response as any);

      dispatch(actions.fetchBalanceSuccess(data));
    } catch (error) {
      dispatch(
        actionsTopHint.openTophint({
          type: 'error',
          text: new Error(error).toString(),
        }),
      );
      dispatch(actions.fetchBalanceFailure(error));
    }
  },

  fetchUser: () => async (dispatch: Dispatch) => {
    try {
      let response = JSON.stringify({});
      dispatch(actions.fetchUserRequest());

      if ((window as any).androidJsBridge) {
        (window as any).androidJsBridge.getUserInfo();

        response = await new Promise((resolve, rej) => {
          (window as any).getUserInfoResult = (data: any) => {
            resolve(data);
          };
        });
      }

      if ((window as any).iosJsBridge) {
        response = await (window as any).iosJsBridge.call('getUserInfo');
      }

      const { error, data } = JSON.parse(response as any);

      if ((error && error.message === 'NOT_AUTHORIZED') || !data) {
        throw error;
      }

      return dispatch(actions.fetchUserSuccess(data));
    } catch (error) {
      dispatch(
        actionsTopHint.openTophint({
          type: 'error',
          text: new Error(error).toString(),
        }),
      );
      dispatch(actions.fetchUserFailure());
    }
  },
};

const reducer = handleActions(
  {
    [actions.fetchBalanceRequest.toString()]: state => ({
      ...state,
      balanceLoading: true,
    }),
    [actions.fetchBalanceSuccess.toString()]: (state, { payload }: any) => ({
      ...state,
      balance: payload,
      balanceLoading: false,
    }),
    [actions.fetchBalanceFailure.toString()]: (
      state,
      { payload: { error } },
    ) => ({
      ...state,
      balanceLoading: false,
    }),

    [actions.fetchUserSuccess.toString()]: (state, { payload }: any) => ({
      ...state,
      user: payload,
      loading: false,
    }),
    [actions.fetchUserRequest.toString()]: state => ({
      ...state,
      loading: true,
    }),
    [actions.fetchUserFailure.toString()]: state => ({
      ...state,
      user: {
        projectID: null,
        userLogin: null,
        isAuthorized: false,
        lang: null,
      },
      loading: false,
    }),

    [actions.resetUser.toString()]: () => ({
      ...initialState,
    }),
  },
  initialState,
);

const getState = (state: any) => state.user;

const cs = (cb: any) =>
  createSelector(
    [getState],
    cb,
  );

const selectors = {
  getUser: cs((s: State) => s.user),
  getBalance: cs((s: State) => s.balance),
  getLoading: cs((s: State) => s.loading),
  getLoadingBalance: cs((s: State) => s.balanceLoading),
  getErrors: cs((s: State) => s.error),
};

export { initialState as state, actions, effects, reducer, selectors };
