import React from 'react';
import { FiAlertCircle, FiX } from 'react-icons/fi';
import './styles.scss';

type Props = {
  text: String;
  closeTophint: () => void;
};

const TopHint = ({ text, closeTophint }: Props) => (
  <div className="container">
    <div className="top-hint__content">
      <div className="top-hint__icon">
        <FiAlertCircle size={24} />
      </div>
      <div className="top-hint__text">
        <span>{text}</span>
      </div>
      <div className="top-hint__close" onClick={closeTophint} aria-hidden>
        <FiX size={24} />
      </div>
    </div>
  </div>
);

export default TopHint;

TopHint.defaultProps = {
  type: 'info',
};
