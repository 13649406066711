import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import ModalTopHint from 'components/Modals/TopHint';
import MenuModals from 'components/Modals/MenuModals';

import history from './lib/history';
import i18n from './i18n';
import configureStore from './redux/store';
import Routes from './views/RootRoutes';

const { store } = configureStore();

const App = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router history={history}>
        <>
          <Routes />
          <ModalTopHint />
          <MenuModals />
        </>
      </Router>
    </I18nextProvider>
  </Provider>
);

export default App;
