import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from 'redux/ducks/modals.duck';
import {
  effects as actionsUser,
  selectors as selectorsUser,
} from 'redux/ducks/user.duck';
import getCurrentDate from 'utils/getCurrentDate';

import TopMenu from 'components/TopMenu';
import NoNotifications from 'components/NoNotifications';
import Notification from 'components/Notification';

import './styles.scss';

type Props = {
  openModal: () => void;
  getBalance: () => void;
  balanceLoading: Boolean;
  list: any;
  balance: any;
  user: any;
};

const Main = ({
  openModal,
  getBalance,
  balance,
  balanceLoading,
  user,
}: Props) => {
  useEffect(() => {
    getBalance();

    return () => {};
  }, [getBalance]);

  return (
    <div className="main-page">
      <TopMenu openModal={openModal} />
      <div className="main-page__bg" />
      <div className="main-page__content">
        <div className="container">
          <div className="main-page__date">
            <span>{getCurrentDate()}</span>
          </div>
          <div className="main-page__balance">
            {balance && (
              <span>
                {Number(balance.balance).toLocaleString(navigator.language, {
                  style: 'currency',
                  currency: balance.currency,
                })}
              </span>
            )}
          </div>
          {user && (
            <div className="main-page__user">
              <div className="icon">
                {user.userLogin.charAt(0).toUpperCase()}
              </div>

              <div className="text">
                <span>{user.userLogin} </span>
              </div>
            </div>
          )}
          <NoNotifications />
          {/* <div className="main-page__list">
            <div className="main-page__title">
              <span>Уведомления</span>
            </div>
            <Notification
              date="06 Августа 2019 · 13:00 "
              message="Креатив “MDP Haval  5562 H-Series  H6  Operators”  успешно создан!"
              type="success"
              icon={<FiCheckCircle size={24} />}
            />
  
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    balance: selectorsUser.getBalance(state),
    balanceLoading: selectorsUser.getLoadingBalance(state),
    user: selectorsUser.getUser(state),
  }),
  { ...actions, ...actionsUser },
)(Main as any);
