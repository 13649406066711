import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  type: 'button' | 'submit';
  title: String;
  onClick?: () => void;
  icon?: String;
  transparent?: Boolean;
  disabled?: boolean | undefined;
};

const Button = ({
  type,
  title,
  onClick,
  icon,
  transparent,
  disabled,
}: Props) => (
  <button
    type={type}
    onClick={onClick}
    className={cn('button', {
      button_transparent: transparent,
      button_hasIcon: icon,
    })}
    disabled={disabled}
  >
    {icon && <span>{icon}</span>}
    {title}
  </button>
);

export default Button;

Button.defaultProps = {
  type: 'button',
};
