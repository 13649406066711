export default {
  translations: {
    // main-menu
    settings: 'Настройки',
    notifications: 'Уведомления',
    language: 'Язык',
    logout: 'Выход',
    notNotifications: 'На данный момент у вас нет уведомлений',

    // project page
    next: 'Далее',
    name_project: 'Название проекта',

    // login
    email: 'E-mail',
    password: 'Пароль',
    forgot_password: 'Забыли пароль?',
    login: 'Войти',
    new_user: 'Новый пользователь',
    sign_up: 'Регистрация',
  },
};
