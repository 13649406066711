import { handleActions, createActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { actions as actionsTopHint } from './tophint.duck';
import { effects as effectsUser, actions as actionsUser } from './user.duck';

type Login = {
  projectId: String;
  email: String;
  password: String;
};

type State = {
  loading: Boolean;
  error: null | string;
  user: null | any;
};

const initialState = {
  loading: false,
  userLoading: false,
  error: null,
};

const actions = createActions(
  'AUTH_LOGIN_SUCCESS',
  'AUTH_LOGIN_REQUEST',
  'AUTH_LOGIN_FAILURE',

  'LOGOUT',
);

const effects = {
  signIn: (props: Login, history: any) => async (dispatch: Dispatch) => {
    try {
      let response = JSON.stringify({});
      const { projectId, email, password } = props;

      dispatch(actions.authLoginRequest());

      if ((window as any).androidJsBridge) {
        (window as any).androidJsBridge.login(projectId, email, password);

        response = await new Promise((resolve, rej) => {
          (window as any).loginResult = (data: any) => {
            resolve(data);
          };
        });
      }

      if ((window as any).iosJsBridge) {
        response = await (window as any).iosJsBridge.call(
          'login',
          [projectId, email, password].join(','),
        );
      }

      const { error } = JSON.parse(response as any);

      if (error) {
        throw error;
      }

      effectsUser.fetchUser()(dispatch);
      dispatch(actions.authLoginSuccess());

      history.push('/');
    } catch (error) {
      dispatch(
        actionsTopHint.openTophint({
          type: 'error',
          text: new Error(error.data || error.message).toString(),
        }),
      );
      dispatch(actions.authLoginFailure());
    }
  },
  logOut: (history: any, closeModal: any) => async (dispatch: Dispatch) => {
    if ((window as any).androidJsBridge) {
      (window as any).androidJsBridge.logout();
    }

    if ((window as any).iosJsBridge && (window as any).iosJsBridge.call) {
      console.log(1);
      await (window as any).iosJsBridge.call('logout');
      console.log(2);
    }

    dispatch(actions.logout());
    dispatch(actionsUser.resetUser());
    effectsUser.fetchUser()(dispatch);
    closeModal();
    history.push('/auth/start');
  },
};

const reducer = handleActions(
  {
    [actions.authLoginRequest.toString()]: state => ({
      ...state,
      loading: true,
    }),
    [actions.authLoginSuccess.toString()]: state => ({
      ...state,
      loading: false,
    }),
    [actions.authLoginFailure.toString()]: state => ({
      ...state,
      loading: false,
    }),

    [actions.logout.toString()]: state => ({
      ...initialState,
      loading: false,
    }),
  },
  initialState,
);

const getState = (state: any) => state.auth;

const cs = (cb: any) =>
  createSelector(
    [getState],
    cb,
  );

const selectors = {
  getUser: cs((s: State) => s.user),
  getLoading: cs((s: State) => s.loading),
  getErrors: cs((s: State) => s.error),
};

export { initialState as state, actions, effects, reducer, selectors };
