export default {
  translations: {
    // main-menu
    settings: 'Settings',
    notifications: 'Notifications',
    language: 'Language',
    logout: 'Logout',
    no_notifications: 'You have no notifications at this time.',

    // project page
    next: 'Next',
    name_project: 'Name project',

    // login
    email: 'Email',
    password: 'Password',
    forgot_password: 'Forgot password?',
    login: 'Login',
    new_user: 'New user',
    sign_up: 'Sign up',
  },
};
