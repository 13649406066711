import React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from 'redux/ducks/modals.duck';
import MainPortal from './MainPortal';
import RenderMenu from './renderMenu';

type Props = {
  isOpen: boolean;
  args?: any;
  type: string;
};

const ModalContent = (props: Props) => {
  const modalRoot = document.getElementById('modal-portal')!;

  return (
    <MainPortal modalRoot={modalRoot}>
      <RenderMenu {...props} />
    </MainPortal>
  );
};

export default connect(
  state => ({
    isOpen: selectors.isOpen(state),
    args: selectors.getArgs(state),
    type: selectors.getType(state),
  }),
  { ...actions },
  // @ts-ignore
)(ModalContent);
