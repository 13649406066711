import React from 'react';

import { Formik, Form } from 'formik';

import Radio from 'components/UI/Radio';
import SettingsCheckbox from 'components/UI/SettingsCheckbox';
import languages from './items';

import './styles.scss';

const LangPage = () => (
  <div className="lang-page__content">
    <div className="lang-page__list">
      <Formik
        onSubmit={() => {}}
        initialValues={{
          lang: 'ru',
          remember: false,
        }}
        render={() => (
          <Form>
            {languages.map((e, i) => (
              <Radio
                type="radio"
                name="lang"
                label={e.label}
                value={e.value}
                key={i}
                disabled={e.disabled}
              />
            ))}
          </Form>
        )}
      />
      <Formik
        onSubmit={() => {}}
        initialValues={{
          lang: 'ru',
          remember: false,
        }}
        render={props => (
          <Form>
            <div className="container">
              <SettingsCheckbox
                label="Push up уведомления"
                name="hello"
                type="checkbox"
              />
              <p>
                Включенные уведомления будут оперативно информировать вас о
                текущем состоянии системы
              </p>
            </div>
          </Form>
        )}
      />
    </div>
  </div>
);

export default LangPage;
