import { handleActions, createActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { actions as actionsTopHint } from './tophint.duck';

type State = {
  loading: Boolean;
  error: null | string;
  list: any;
  item: any;
};

const initialState = {
  list: [],
  item: null,
  loading: false,
  error: null,
};

const actions = createActions(
  'FETCH_LIST_PROJECT_REQUEST',
  'FETCH_LIST_PROJECT_SUCCESS',
  'FETCH_LIST_PROJECT_FAILURE',

  'FETCH_PROJECT_REQUEST',
  'FETCH_PROJECT_SUCCESS',
  'FETCH_PROJECT_FAILURE',
);

const effects = {
  getListProjects: () => async (dispatch: Dispatch) => {
    try {
      let response;
      dispatch(actions.fetchListProjectRequest());

      if ((window as any).androidJsBridge) {
        (window as any).androidJsBridge.getProjectList();

        response = await new Promise((resolve, rej) => {
          (window as any).getProjectListResult = (data: any) => {
            resolve(data);
          };
        });
      }

      if ((window as any).iosJsBridge) {
        response = await (window as any).iosJsBridge.call('getProjectList');
      }

      const { data } = JSON.parse(response as any);

      dispatch(actions.fetchListProjectSuccess(data));
    } catch (error) {}
  },

  getProject: (name: String) => async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchProjectRequest());

      if ((window as any).androidJsBridge) {
        (window as any).androidJsBridge.getProjectByName(name);

        const response = await new Promise((resolve, rej) => {
          (window as any).getProjecResult = (data: any) => {
            resolve(data);
          };
        });

        dispatch(actions.fetchProjectSuccess(response));
      }
    } catch (error) {}
  },
};

const reducer = handleActions(
  {
    [actions.fetchListProjectRequest.toString()]: state => ({
      ...state,
      loading: true,
    }),
    [actions.fetchListProjectSuccess.toString()]: (
      state,
      { payload }: any,
    ) => ({
      ...state,
      list: payload,
      loading: false,
    }),
    [actions.fetchListProjectFailure.toString()]: (
      state,
      { payload: { error } },
    ) => ({
      ...state,
      error: error,
      loading: false,
    }),

    [actions.fetchProjectRequest.toString()]: state => ({
      ...state,
      loading: true,
    }),
    [actions.fetchProjectSuccess.toString()]: (state, { payload }: any) => ({
      ...state,
      item: payload,
      loading: false,
    }),
    [actions.fetchProjectFailure.toString()]: (
      state,
      { payload: { error } },
    ) => ({
      ...state,
      error: error,
      loading: false,
    }),
  },
  initialState,
);

const getState = (state: any) => state.project;

const cs = (cb: any) =>
  createSelector(
    [getState],
    cb,
  );

const selectors = {
  getItem: cs((s: State) => s.item),
  getList: cs((s: State) => s.list),
  getLoading: cs((s: State) => s.loading),
  getErrors: cs((s: State) => s.error),
};

export { initialState as state, actions, effects, reducer, selectors };
