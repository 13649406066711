import React from 'react';
import LangPage from 'components/LangPage';
import MenuPage from 'components/MenuPage';
import NotificationPage from 'components/NotificationPage';
import ModalContainer from './ModalContainer';

type Props = {
  isOpen: boolean;
  args?: any;
  type: string;
};

const RenderModal = ({ type, args, ...props }: Props) => {
  switch (type) {
    case 'languages':
      return (
        <ModalContainer {...props} {...args}>
          <LangPage />
        </ModalContainer>
      );
    case 'main':
      return (
        <ModalContainer {...props} {...args}>
          <MenuPage />
        </ModalContainer>
      );
    case 'notification':
      return (
        <ModalContainer {...props} {...args} slide="bottom">
          <NotificationPage />
        </ModalContainer>
      );
    default:
      return null;
  }
};

export default RenderModal;
