import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: ReactNode;
  modalRoot: HTMLElement;
};

const MainPortal = ({ children, modalRoot }: Props) =>
  createPortal(children, modalRoot);

MainPortal.defaultProps = {
  modalRoot: document.getElementById('portal-tophint'),
};

export default MainPortal;
