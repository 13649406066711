export default [
  {
    label: 'Русский',
    value: 'ru',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Español (España)',
    value: 'es',
    disabled: true,
  },
  {
    label: 'Hrvatski',
    value: 'hr',
    disabled: true,
  },
  {
    label: 'Indonesia',
    value: 'in',
    disabled: true,
  },
  {
    label: 'Italiano',
    value: 'it',
    disabled: true,
  },
  {
    label: 'Nederlands',
    value: 'ne',
    disabled: true,
  },
  {
    label: 'Polski',
    value: 'pol',
    disabled: true,
  },
  {
    label: 'Português (Brasil)',
    value: 'por',
    disabled: true,
  },
  {
    label: 'Română',
    value: 'ro',
    disabled: true,
  },
  {
    label: 'Slovenčina',
    value: 'sl',
    disabled: true,
  },
  {
    label: 'বাংলা',
    value: 'ko',
    disabled: true,
  },
  {
    label: '日本語',
    value: 'ch',
    disabled: true,
  },
];
