import React from 'react';
import cn from 'classnames';
import { MdInfoOutline } from 'react-icons/md';
import input from './styles.module.scss';

type Props = {
  label: String;
  children: React.ReactNode;
  error?: boolean | undefined;
  errortext?: String | any;
};

const CustomSelect = ({ label, children, error, errortext }: Props) => (
  <div
    className={cn(input['form-group'], error ? input['form-group_error'] : '')}
  >
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    {label && <label htmlFor="#">{label}</label>}
    <div>{children}</div>

    {!!errortext && (
      <span className={input['form-group__errorText']}>
        <MdInfoOutline size="16" />
        {errortext}
      </span>
    )}
  </div>
);

export default CustomSelect;
