import React from 'react';
import cn from 'classnames';
import i18n from 'i18next';
import { FiBellOff } from 'react-icons/fi';

import './styles.scss';

const NoNotifications = () => (
  <div className={cn('no-notifications')}>
    <div className="no-notifications__icon">
      <FiBellOff size={44} color="#aaa" />
    </div>
    <div className="no-notifications__text">
      <span>{i18n.t('no_notifications')}</span>
    </div>
  </div>
);

export default NoNotifications;
