import React from 'react';
import cn from 'classnames';
import { FiMenu } from 'react-icons/fi';

// eslint-disable-next-line import/no-unresolved
import avatar from 'assets/avatar.svg';
import './styles.scss';

type OpenModalProp = {
  type: String;
};

type Props = {
  scroll?: String;
  message?: String;
  openModal: (val: OpenModalProp) => void;
};

const TopMenu = ({ scroll, message, openModal }: Props) => {
  return (
    <div className={cn('top-menu', { scroll, message })}>
      <div className="top-menu__content">
        <div className="container">
          <div
            className="top-menu__user"
            style={{ background: `url(${avatar})` }}
          />
          <div className="top-menu__balance">
            <span>12 333, 56 ₽</span>
          </div>
          <div
            className="top-menu__hamburger"
            onClick={() =>
              openModal({
                type: 'main',
              })
            }
            aria-hidden
          >
            <FiMenu size={24} />
            <span />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
