import React from 'react';
import { Redirect, Route } from 'react-router-dom';

type Props = {
  component: any;
  exact?: any;
  path: string;
  hasUser: any;
};

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  hasUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return hasUser ? <Component {...props} /> : <Redirect to="/auth/start" />;
    }}
  />
);

export default PrivateRoute as any;
